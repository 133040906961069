<template>
  <div class="table">
    <div class="table__content">
      <div class="table__tile">
        <div class="table__tile-left">
          <slot name="left-headings" />
        </div>
        <div class="table__tile-right">
          <slot name="right-headings" />
        </div>
      </div>
      <slot></slot>
      <!-- <div class="table__tile">
        <div class="content-wrapper">
          <slot name="content" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<style lang="sass">
.table
  p
    margin: 0
    margin-bottom: 0.5rem
  &__tile
    display: flex
    justify-content: space-between
    &:first-child > *
      & > *
        color: $text-grey
        font-size: 14px
  &__tile-left, &__tile-right
    display: flex
    align-items: center
    & > *
      margin: 0 1rem
      width: 100%
      text-align: center
  &__tile-right
    margin-right: 2rem
</style>
