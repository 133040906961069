<template>
  <div>
    <DashboardSection>
      <template #top-right-button>
        <CustomButton iconClass="plus" label="New invoice" color="grey" size="small" />
      </template>
      <template #content>
        <TableLayout>
          <template #left-headings>
            <p class="left-heading-item">Invoice #</p>
            <p class="left-heading-item mobile-hide">Date</p>
            <p class="left-heading-item mobile-hide">PO number</p>
            <p class="left-heading-item mobile-hide">Amount</p>
          </template>
          <template #right-headings>
            <p class="right-heading-item">Status</p>
          </template>

          <TableTile v-for="invoice in invoices" :key="invoice">
            <template #tileContent>
              <router-link :to="{ name: 'Projects' }" :class="invoice.class">
                <div class="left-content">
                  <div class="left-text">{{ invoice.name }}</div>
                  <div class="mobile-hide">{{ invoice.date }}</div>
                  <div class="mobile-hide">{{ invoice.number }}</div>
                  <div class="mobile-hide">{{ invoice.amount }}</div>
                </div>
                <div class="right-content">
                  <div>
                    <p :class="invoice.statusClass">{{ invoice.status }}</p>
                  </div>
                </div>
              </router-link>
            </template>
          </TableTile>
        </TableLayout>
      </template>
    </DashboardSection>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import TableLayout from '@/components/templates/TableLayout.vue'
import TableTile from '@/components/templates/TableTile.vue'
export default defineComponent({
  components: {
    DashboardSection,
    CustomButton,
    TableLayout,
    TableTile
  },
  setup() {
    const invoices = [
      {
        id: 1,
        name: 'PJ-INV004',
        date: '23/07/21',
        number: 'PO#123456',
        amount: '£45,908.67',
        status: 'Overdue',
        class: 'tile-border-red',
        statusClass: 'status-red'
      },
      {
        id: 2,
        name: 'PJ-INV003',
        date: '23/07/21',
        number: 'PO#123456',
        amount: '£45,908.67',
        status: 'Due',
        class: 'tile-border-org',
        statusClass: 'status-org'
      },
      {
        id: 3,
        name: 'PJ-INV002',
        date: '23/07/21',
        number: 'PO#123456',
        amount: '£45,908.67',
        status: 'Partially Paid',
        class: 'tile-border-org',
        statusClass: 'status-org'
      },
      {
        id: 4,
        name: 'PJ-INV001',
        date: '23/07/21',
        number: 'PO#123456',
        amount: '£45,908.67',
        status: 'Paid',
        class: 'tile-border-grn',
        statusClass: 'status-grn'
      },
      {
        id: 5,
        name: 'PJ-INV005',
        date: '23/07/21',
        number: 'PO#123456',
        amount: '£45,908.67',
        status: 'Upcoming',
        class: 'tile-border-grey',
        statusClass: 'status-grey'
      }
    ]
    return {
      invoices
    }
  }
})
</script>
<style lang="sass" scoped>
$leftWidth: 8rem
$rightWidth: 5rem
.tile-tile
  font-weight: bold
  font-size: 16px
.tile-subtitle
  font-size: 12px
  color: map-get($greyShades, 'greyShade80')
.left-content, .right-content
  display: flex
  align-items: center
  & > *
    text-align: center
.left-content > *
  min-width: $leftWidth
.right-content > *
  min-width: $rightWidth
.right-heading-item
  min-width: $rightWidth
.left-heading-item
  min-width: $leftWidth
.left-text
  text-align: left
.mobile-hide
  @media( max-width: $md-breakpoint)
    display: none
.status-red
  background-color: #E4536B
  height: 26px
  padding-top: 6px
  color: white
.status-org
  background-color: #F3A446
  height: 26px
  padding-top: 6px
  color: white
.status-grn
  background-color: #2FC397
  height: 26px
  padding-top: 6px
  color: white
.status-grey
  background-color: #B7BCC3
  height: 26px
  padding-top: 6px
  color: white
.tile-border-red
  border-left: solid #E4536B 4px
.tile-border-org
  border-left: solid #F3A446 4px
.tile-border-grn
  border-left: solid #2FC397 4px
</style>
